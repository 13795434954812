import './Logo.scss';
import Link from 'next/link';

const Logo = () => (
	<div id="logo">
		<Link href="/">
			<a>Dan Blaax Admin</a>
		</Link>
	</div>
);

export default Logo;
