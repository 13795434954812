// Dependencies

// Components
import Logo from '../logo/Logo';
import links from '../../../data/navbar-links';
import { observer } from 'mobx-react';
import useStores from '../../helpers/useStores';
import { NavBar } from '@anephenix/ui';

// Styling
import './Page.scss';

const Page = ({ children }) => {
	const stores = useStores();
	const sessionStore = stores.sessionStore;
	const { hasLoaded, session } = sessionStore;
	const loggedIn = hasLoaded && session;
	return (
		<div id="page">
			<NavBar logo={<Logo />} links={links} loggedIn={loggedIn} />

			<div id="content" className="container">
				<div className="withSidePadding">{children}</div>
			</div>
		</div>
	);
};
export default observer(Page);
