// Dependencies
import Router from 'next/router';

export const redirectIfLoggedIn = (props, component) => {
	const { hasLoaded, session } = props.sessionStore;

	// Don't do anything until we've retrieved the session
	if (!hasLoaded) return null;

	if (session) {
		Router.push({ pathname: '/dashboard' });
		return null;
	}
	if (typeof component !== 'function') return component;
	return component();
};

export const redirectIfLoggedOut = (props, component) => {
	const { hasLoaded, session } = props.sessionStore;

	// Don't do anything until we've retrieved the session
	if (!hasLoaded) return null;

	if (!session) {
		Router.push({ pathname: '/' });
		return null;
	}
	if (typeof component !== 'function') return component;
	return component();
};
