import sessionStore from '../src/stores/sessionStore';

const links = [
	{
		text: 'Home',
		url: '/',
		hideOnDesktop: true,
		hideOptions: ({ loggedIn }) => loggedIn,
	},
	{ text: 'Users', url: '/users', hideOptions: ({ loggedIn }) => loggedIn },
	{
		text: 'Products',
		url: '/products',
		hideOptions: ({ loggedIn }) => loggedIn,
	},
	{ text: 'Plans', url: '/plans', hideOptions: ({ loggedIn }) => loggedIn },
	{ text: 'Queues', url: '/queues', hideOptions: ({ loggedIn }) => loggedIn },
	{
		text: 'Customers',
		url: '/customers',
		hideOptions: ({ loggedIn }) => loggedIn,
	},
	{
		text: 'Tracks',
		url: '/tracks',
		hideOptions: ({ loggedIn }) => loggedIn,
	},
	{
		text: 'Playlists',
		url: '/playlists',
		hideOptions: ({ loggedIn }) => loggedIn,
	},
	{
		text: 'Samples',
		url: '/samples',
		hideOptions: ({ loggedIn }) => loggedIn,
	},
	{
		text: 'Login',
		url: '/login',
		hideOptions: ({ loggedIn }) => !loggedIn,
	},
	{
		text: 'Logout',
		id: 'logout',
		onClick: () => {
			sessionStore.delete();
		},
		hideOptions: ({ loggedIn }) => loggedIn,
	},
];

export default links;
