// Dependencies
import Page from '../src/components/page/Page';
import React from 'react';
import { observer } from 'mobx-react';
import useStores from '../src/helpers/useStores';

// Helpers
import { redirectIfLoggedIn } from '../src/helpers/redirect';

// Styling
import '../styles/index.scss';

const HomePage = observer(() => {
	return redirectIfLoggedIn(
		useStores(),
		<Page>
			<div>Welcome to DanBlaax Admin</div>
		</Page>
	);
});

export default HomePage;
